import React, { FC } from 'react';
import cx from 'classnames';

type TStarIconProps = {
  className?: string;
};

export const StarIcon: FC<TStarIconProps> = ({ className }) => (
  <svg
    className={cx(className)}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.20479 12.1181C5.98402 10.8524 5.08625 9.26292 3.379 7.98249C2.5401 7.34963 1.68648 6.93754 0.847572 6.76093V6.14279C2.51066 5.74541 4.14432 4.62687 5.18927 3.11096C5.7191 2.34564 6.05761 1.59504 6.20479 0.815008H6.82293C7.07313 2.30149 8.2211 3.94986 9.78117 5.05368C10.5465 5.59823 11.3412 5.96618 12.1507 6.14279V6.76093C10.517 7.09943 8.61848 8.55648 7.67655 10.043C7.20558 10.7936 6.92595 11.4853 6.82293 12.1181H6.20479Z"
      fill=""
    />
  </svg>
);
