import React, { FC } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import Typo from '../Typography/Typo';

type TPostCardHeaderUserProfileProps = {
  profileImage?: TCloudinaryImageShape;
  authorLabel: string;
  authorProfileURL: string;
  forceMobileView?: boolean;
};

export const PostCardHeaderUserProfile: FC<TPostCardHeaderUserProfileProps> = ({
  profileImage,
  authorLabel,
  authorProfileURL,
  forceMobileView = false,
}) => (
  <Link href={authorProfileURL}>
    <div className="flex group/user gap-2">
      <div
        className={cx('hidden group-hover/user:opacity-[0.8]', {
          'md:flex': !forceMobileView,
        })}
      >
        <UserProfileImage size="small" profileImage={profileImage as TCloudinaryImageShape} />
      </div>
      <div className="flex flex-col justify-center">
        <Typo
          className={cx(
            'max-w-[100px] overflow-hidden',
            '!text-xs md:!text-sm text-t2-neutral-grey09 text-ellipsis whitespace-nowrap',
            'group-hover/user:opacity-[0.8] group-hover/user:text-t2-primary-brandColor',
            {
              'md:max-w-[150px]': !forceMobileView,
            },
          )}
        >
          {authorLabel}
        </Typo>
      </div>
    </div>
  </Link>
);
