import React, { FC, ReactNode } from 'react';
import Link from 'next/link';
import cx from 'classnames';

type TBaseCardProps = {
  url: string;
  children: ReactNode;
  className?: string;
  forceMobileView?: boolean;
  cardType: 'post' | 'prompt';
};

export const BaseCard: FC<TBaseCardProps> = ({
  url,
  children,
  className,
  forceMobileView = false,
  cardType = 'post',
}) => (
  <div
    className={cx(
      'relative flex items-start gap-4 md:gap-0 group bg-t2-neutral-grey02 hover:bg-t2-shades-white rounded-[16px] z-0',
      {
        'p-2 ': cardType === 'post',
        'flex-col md:min-h-[400px] p-4 md:justify-between': cardType === 'prompt',
        'md:flex-col md:gap-0': !forceMobileView,
      },
      className,
    )}
  >
    <Link className="absolute inset-0 w-full" href={url}>
      {' '}
    </Link>
    {children}
  </div>
);
