import React, { FC } from 'react';
import cx from 'classnames';
import { Placeholder } from '../Placeholder/Placeholder';

const PostCardImagePlaceholder = ({ forceMobileView = false }) => (
  <div
    className={cx('relative md:w-full md:h-full', {
      'md:!w-[95px] md:!h-[95px]': forceMobileView,
    })}
  >
    <Placeholder
      className={cx('flex h-[75px] w-[75px] rounded-[8px] flex-shrink-0', {
        'md:w-full md:h-[165px]': !forceMobileView,
        ' md:h-[95px] md:w-[95px]': forceMobileView,
      })}
    />
    <Placeholder
      className={cx('hidden absolute w-[40px] h-[20px] bottom-4 bg-t2-neutral-grey02 right-3 rounded-md', {
        'md:flex': !forceMobileView,
      })}
    />
  </div>
);

const PostCardAuthorPlaceholder = ({ forceMobileView = false }) => (
  <div
    className={cx('flex md:w-full md:mt-3 items-center gap-2 order-2 md:order-1', {
      '!mt-0': forceMobileView,
    })}
  >
    <Placeholder className="flex h-[32px] w-[32px] rounded-full" />
    <Placeholder className="h-[16px] w-[100px] rounded-md" />
    <Placeholder
      className={cx('hidden md:flex w-[80px] ml-auto h-[16px] rounded-md mr-1', {
        '!hidden': forceMobileView,
      })}
    />
  </div>
);

const PostCardTitlePlaceholder = ({ forceMobileView = false }) => (
  <div
    className={cx('flex md:mt-5 flex-col gap-2 w-full order-1 md:order-2', {
      '!mt-0': forceMobileView,
    })}
  >
    <Placeholder
      className={cx('h-[18px] w-4/5 md:w-full rounded-md', {
        '!w-4/5': forceMobileView,
      })}
    />
    <Placeholder className="h-[18px] w-[80px] rounded-md" />
  </div>
);

const PostCardDescriptionPlaceholder = ({ forceMobileView = false }) => (
  <div
    className={cx('hidden md:flex mt-5 flex-col gap-2 w-full', {
      '!hidden': forceMobileView,
    })}
  >
    <Placeholder className="h-[16px] w-4/5 rounded-md" />
    <Placeholder className="h-[16px] w-4/5 rounded-md" />
    <Placeholder className="h-[16px] w-2/5 rounded-md" />
  </div>
);

const PostCardFooterPlaceholder = ({ forceMobileView = false }) => (
  <Placeholder
    className={cx('hidden md:flex flex-shrink-0 mt-5 h-[16px] w-[70px] rounded-md', {
      '!hidden': forceMobileView,
    })}
  />
);

type TPostCardLoaderProps = {
  forceMobileView?: boolean;
};

export const PostCardLoader: FC<TPostCardLoaderProps> = ({ forceMobileView = false }) => (
  <div
    className={cx(
      'relative p-2 flex items-center md:items-start gap-4 md:gap-0 bg-t2-neutral-grey02 rounded-[16px] animate-pulse',
      {
        '!gap-4 !items-center': forceMobileView,
        'md:flex-col md:gap-0': !forceMobileView,
      },
    )}
  >
    <PostCardImagePlaceholder forceMobileView={forceMobileView} />
    <div className="flex flex-col gap-2 w-full">
      <PostCardAuthorPlaceholder forceMobileView={forceMobileView} />
      <PostCardTitlePlaceholder forceMobileView={forceMobileView} />
    </div>
    <PostCardDescriptionPlaceholder forceMobileView={forceMobileView} />
    <PostCardFooterPlaceholder forceMobileView={forceMobileView} />
  </div>
);
