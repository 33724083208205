import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

type TPostCardImageInfoTileProps = {
  children: ReactNode;
  backgroundColor?: 'white' | 'translucent';
};

export const PostCardImageInfoTile: FC<TPostCardImageInfoTileProps> = ({
  children,
  backgroundColor = 'translucent',
}) => (
  <div
    className={classNames('flex p-1 items-center gap-1 rounded-md text-xs text-t2-neutral-grey08 ', {
      'bg-t2-shades-white/70': backgroundColor === 'translucent',
      'bg-t2-shades-white': backgroundColor === 'white',
    })}
  >
    {children}
  </div>
);
