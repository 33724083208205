import Image from 'next/image';
import React, { FC } from 'react';
import cx from 'classnames';
import { TArticleCardProps } from '../ArticleCard/ArticleCard';
import { DEFAULT_ARTICLE_IMAGE } from '../ArticleCard/constants';
import { PostCardImageInfoTile } from './PostCardImageInfoTile';
import { NotificationSuccessIcon } from '../NotificationSuccessIcon/NotificationSuccessIcon';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage/CloudinaryResponsiveImage';

type TPostCardImageProps = {
  article: TArticleCardProps;
  forceMobileView?: boolean;
};

export const PostCardImage: FC<TPostCardImageProps> = ({ article, forceMobileView = false }) => (
  <div
    className={cx('flex relative h-[75px] w-[75px] rounded-[8px] overflow-hidden -z-10 flex-shrink-0', {
      'md:w-full md:h-[165px]': !forceMobileView,
      ' md:h-[95px] md:w-[95px]': forceMobileView,
    })}
  >
    {article?.coverImage ? (
      <CloudinaryResponsiveImage
        title={article?.title}
        aspectRatio={1}
        image={article?.coverImage}
        alt="article image"
        className="absolute object-cover h-[100%] w-[100%] -z-10"
        containerClassName="w-[100%]"
      />
    ) : (
      <Image priority src={DEFAULT_ARTICLE_IMAGE} alt="article image" layout="fill" objectFit="cover" />
    )}
    <div
      className={cx('hidden absolute bottom-[10px] right-2 gap-2', {
        'md:flex': !forceMobileView,
      })}
    >
      {article?.hasRead && (
        <PostCardImageInfoTile>
          <NotificationSuccessIcon className="w-3 h-3" color="#242424" />
          Read
        </PostCardImageInfoTile>
      )}
      <PostCardImageInfoTile>{article?.readTime} min</PostCardImageInfoTile>
    </div>
  </div>
);
