import React, { FC } from 'react';
import cx from 'classnames';
import { PostCardFooterList } from './PostCardFooterList';
import { PostCardFooterListItem } from './PostCardFooterListItem';
import { StarIcon } from '../Icons/StarIcon';
import { MessageCircleIcon } from '../Icons/MessageCircleIcon';
import ThreeDotsIcon from '../Icons/ThreeDotsIcon';
import { PinIcon } from '../Icons/PinIcon';
import Dropdown from '../Dropdown/Dropdown';
import { CardDropdownMenu } from '../CardDropdownMenu/CardDropdownMenu';
import { TArticleCardProps } from '../ArticleCard/ArticleCard';
import { formatLargeNumber } from '../../../utils/formatLargeNumber';

export const getPostCardFooterList = (totalTimePointsAccumulated: string, commentsCount: number) => [
  {
    key: 'timePoints',
    name: `${formatLargeNumber(Math.floor(+totalTimePointsAccumulated)) || 0} t.p.`,
    icon: <StarIcon className="fill-t2-neutral-grey05 group-hover:fill-t2-neutral-grey06" />,
  },
  {
    key: 'comments',
    name: commentsCount || 0,
    icon: <MessageCircleIcon className="stroke-t2-neutral-grey05 group-hover:stroke-t2-neutral-grey06" />,
  },
];

export const getDropdownMenuButtons = (territoryPinnedAt: string, onClick: () => void) => [
  {
    key: 'pin',
    text: territoryPinnedAt ? 'Unpin Post' : 'Pin Post',
    icon: <PinIcon className="w-4 h-4 self-start md:self-center fill-none stroke-t2-neutral-grey09" />,
    onClick,
  },
];

type TPostCardFooterProps = {
  article: TArticleCardProps;
  canManagePinnedArticles?: boolean;
  isPinningEnabled?: boolean;
  onPinClick: () => void;
  forceMobileView?: boolean;
};

export const PostCardFooter: FC<TPostCardFooterProps> = ({
  article,
  canManagePinnedArticles,
  isPinningEnabled,
  onPinClick,
  forceMobileView = false,
}) => {
  const footerList = getPostCardFooterList(article?.totalTimePointsAccumulated, article?.commentsCount);
  const menuButtons = getDropdownMenuButtons(article?.territoryPinnedAt!, onPinClick);

  return (
    <div
      className={cx('hidden w-full mt-7 mb-2', {
        'md:flex': !forceMobileView,
      })}
    >
      <PostCardFooterList>
        {footerList.map((item) => (
          <PostCardFooterListItem key={item.key}>
            <span>{item.icon}</span>
            <span>{item.name}</span>
          </PostCardFooterListItem>
        ))}
      </PostCardFooterList>
      <div
        className={cx('hidden items-center ml-auto', {
          'md:flex': !forceMobileView,
        })}
      >
        {isPinningEnabled && article?.territoryPinnedAt && (
          <PinIcon className="w-3 h-3 stroke-t2-primary-brandColor fill-t2-primary-brandColor -z-10 mr-2" />
        )}
        {canManagePinnedArticles && (
          <div className="z-30 h-6">
            <Dropdown
              label={<ThreeDotsIcon />}
              menu={<CardDropdownMenu buttons={menuButtons} />}
              position="right"
              menuContainerClassName="bottom-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
            />
          </div>
        )}
      </div>
    </div>
  );
};
