import React, { FC, ReactNode } from 'react';

type TPostCardFooterListItemProps = {
  children: ReactNode;
};

export const PostCardFooterListItem: FC<TPostCardFooterListItemProps> = ({ children }) => (
  <li className="flex items-center gap-1 px-[10px] py-[2px] text-sm border border-t2-neutral-grey03 text-t2-neutral-grey05 group-hover:text-t2-neutral-grey06 rounded-2xl">
    {children}
  </li>
);
